<script setup lang="ts">
const { isShoppable } = await useIsShoppable()
//get menu info and handlers
const {
  menuStatus,
  isLogged: isUserLogged,
  isListViewOpen,
  toggleMyAccount,
  toggleMenu,
  toggleMenuInPdp,
  toggleListView,
  openSupportView,
  openSearchView,
} = useMenu()

//get product info
const disabled = useState<boolean | undefined>('disabledMobileAddToCart')
const selectedExtOutOfStock = useState<boolean | undefined>(
  'selectedExtOutOfStockMobile'
)
const onlyStore = useState<boolean | undefined>('onlyStoreMobile')
</script>

<template>
  <ul class="flex [&>li]:flex [&>li]:items-center">
    <slot
      :is-shop-active="isShoppable"
      :menu-status="menuStatus"
      :add-to-card-status="{
        disabled,
        selectedExtOutOfStock,
        onlyStore,
      }"
      :is-user-logged="isUserLogged"
      :is-list-view-active="isListViewOpen"
      :toggle-my-account="toggleMyAccount"
      :toggle-menu="toggleMenu"
      :toggle-menu-in-pdp="toggleMenuInPdp"
      :toggle-list-view="toggleListView"
      :open-support="openSupportView"
      :open-search="openSearchView"
    />
  </ul>
</template>
