<script setup lang="ts">
import AccountNoLog from '~icons/icons/AccountNoLog.svg'
import AccountLog from '~icons/icons/AccountLog.svg'
import Country from '~icons/icons/Country.svg'
import Heart from '~icons/icons/Heart.svg'
import Pin from '~icons/icons/Pin.svg'
import Support from '~icons/icons/Support.svg'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type {
  HeaderMenuNavGlobalLinksProps,
  SingleNavItem,
} from './HeaderMenuNavGlobalLinks.props'

const { openDialog, closeAllDialogs } = useDialog()
const { closeLast } = useMenu()
const { $ts } = useI18n()
const { isLogged } = useLogin()
const { getCountryName, getLanguageName } = useCountryHelper()
const { country, language, myAccount, wishlist, guestWishlist, storeLocator } =
  useRouteHelper()
const { dispatchActionContactUsEvent } = useGAActionEvents()
const props = defineProps<HeaderMenuNavGlobalLinksProps>()
const { isShoppable } = await useIsShoppable()
const countryLabel = `${getCountryName(country)} - ${getLanguageName(language)}`

const iconMapping: Record<string, string> = {
  account: isLogged.value ? AccountLog : AccountNoLog,
  heart: Heart,
  pin: Pin,
  support: Support,
  country: Country,
}

const labelMapping: Record<string, string> = {
  account: $ts('header.account'),
  heart: $ts('header.wishlist'),
  pin: $ts('header.storeLocator'),
  support: $ts('header.customerService'),
  country: countryLabel,
}

const linkDialogMapping: Record<
  string,
  { link?: string; dialogId?: string; ariaLabel?: string }
> = {
  account: {
    link: isLogged.value ? myAccount : undefined,
    dialogId: !isLogged.value ? SIDEBAR_IDS.loginSidebar : undefined,
  },
  heart: {
    link: isLogged.value ? wishlist : guestWishlist,
  },
  pin: {
    link: storeLocator,
  },
  support: {
    dialogId: SIDEBAR_IDS.cartContactUsSideSlide,
  },
  country: {
    dialogId: SIDEBAR_IDS.footerAsideLanguage,
    ariaLabel: `${$ts('footer.firstBlock.countrySelector.title')}`,
  },
}

const globalLinks = computed(() =>
  props.items
    .map((item: SingleNavItem) => {
      const shouldDisplay =
        isShoppable.value ||
        (item.trackId !== 'header_customer-service' &&
          item.trackId !== 'header_account' &&
          item.trackId !== 'header_whishlist')

      if (shouldDisplay) {
        const label = labelMapping[item?.icon!] || ''
        const icon = iconMapping[item?.icon!] || ''
        const linkDialog = linkDialogMapping[item?.icon!] || {}

        return {
          label,
          icon,
          ...linkDialog,
          trackId: item.trackId,
        }
      }
    })
    .filter(item => item !== undefined)
)

const {
  menuStatus,
  isFederatedMenu,
  cleanMenuStatus,
  navigateToMyAccountPage,
  navigateToSupportPage,
} = useMenu()

const handleDialog = (dialogId: string) => {
  if (dialogId === SIDEBAR_IDS.loginSidebar) {
    if (isFederatedMenu.value) {
      navigateToMyAccountPage()
      return
    }
  }

  if (dialogId === SIDEBAR_IDS.cartContactUsSideSlide) {
    if (isFederatedMenu.value) {
      navigateToSupportPage()
      return
    }

    dispatchActionContactUsEvent()
  }
  openDialog(dialogId)
  closeLast()
}

const handleDialogGlobalLink = () => {
  if (menuStatus.value.isActive) {
    cleanMenuStatus()
  }
}
</script>

<template>
  <ClientOnly>
    <ul class="text-book-6 grid gap-4">
      <li v-for="globalLink in globalLinks" :key="globalLink.trackId">
        <MoleculesLinkWrapper
          v-if="globalLink.link"
          anatomy="link"
          :to="globalLink.link"
          :link-icon-bigger="true"
          :icon-position="'left'"
          :underline-effect="'appear'"
          class="!items-center"
          @click="handleDialogGlobalLink()"
        >
          <template #iconLink>
            <component
              :is="globalLink.icon"
              class="h-6 w-6"
              aria-hidden="true"
            />
          </template>
          <template #label>
            {{ globalLink.label }}
          </template>
        </MoleculesLinkWrapper>
        <AtomsCta
          v-else-if="globalLink.dialogId"
          anatomy="link"
          :link-icon-bigger="true"
          :icon-position="'left'"
          :underline-effect="'appear'"
          :aria-label="`${globalLink.label} - ${globalLink.ariaLabel}`"
          class="!items-center"
          aria-haspopup="dialog"
          @click="handleDialog(globalLink.dialogId)"
        >
          <template #icon>
            <component
              :is="globalLink.icon"
              class="h-6 w-6"
              aria-hidden="true"
            />
          </template>
          <template #label>
            {{ globalLink.label }}
          </template>
        </AtomsCta>
      </li>
    </ul>
  </ClientOnly>
</template>
