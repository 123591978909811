<script setup lang="ts">
import type { FooterSecondBlockSection } from './FooterSecondBlock.props'
import startCase from 'lodash/startCase'
import { openCountries as countries } from '@design-system/configs/countries'
import { hasProtocol, withLeadingSlash } from 'ufo'

const { isHome } = useRouteHelper()
const { dispatchStoreLocatorEvent, dispatchFooterEvent, dispatchSocialEvent } =
  useGANavigationEvents()

const { data: footerLinks, refresh } = await useFooter('footer-links')
// @ts-ignore
const { eventBus } = useFederatedEventBus()
const normalizeUrl = (url: string) => {
  return hasProtocol(url) ? url : withLeadingSlash(url)
}

if (useRuntimeConfig().public.isFederated) {
  // @ts-ignore
  window?.eventBus?.$on('setLocaleEvent', async () => {
    refresh()
  })
}

const localePath = useLocalePath()
const { isShoppable } = await useIsShoppable()

const footerSecondBlock = computed(() => {
  const uniqueIdsToHide = [
    'footer-contact-us',
    'footer-help-area',
    'footer-faq',
    'footer-my-account',
    'shipping',
    'track-orders',
    'footer-track-my-order',
    'footer-returns-and-refunds',
    'footer-terms-of-sales',
  ]

  return (
    footerLinks?.value?.content?.footer
      ?.filter(item => {
        const showInAllCountries = item.showInAllCountries
        const showInCountry = item.showInCountry
        // If both are undefined, return true
        if (showInAllCountries === undefined && showInCountry === undefined) {
          return true
        }
        // If showInAllCountries is false, check showInCountry
        if (showInAllCountries === false) {
          return showInCountry !== false
        }
        // Otherwise, return true
        return true
      })
      ?.map((item: any) => {
        const filteredLinks = item.urls
          ?.filter((link: any) => {
            const shouldHide = uniqueIdsToHide.includes(link.uniqueId)
            const result = isShoppable.value || !shouldHide
            return result
          })
          ?.map((link: any) => {
            const isExternal =
              hasProtocol(link.target?.target) ?? link?.target?.type === 'blank'
            return {
              label: link.target?.label,
              url: link.target?.internalLink?.urlSlug
                ? localePath(normalizeUrl(link.target.internalLink?.urlSlug))
                : isExternal
                  ? normalizeUrl(link.target?.target)
                  : localePath(normalizeUrl(link.target?.target)),
              external: isExternal,
              trackId: link?.uniqueId,
            }
          })

        // Only return the item if it has filtered links
        if (filteredLinks && filteredLinks.length > 0) {
          return {
            title: item.titleSection,
            links: filteredLinks,
          } as FooterSecondBlockSection
        }
      })
      .filter(item => item) ?? [] // Filter out undefined items
  )
})
const handleLinkClick = (section: string, id: string | undefined) => {
  if (!id) return
  if (id === 'find-a-boutique') dispatchStoreLocatorEvent()
  dispatchFooterEvent(section, startCase(id))
}
</script>

<template>
  <footer :class="[isHome && 'lg:snap-start lg:snap-always']">
    <h2 class="sr-only">{{ $ts('accessibility.footer') }}</h2>
    <FooterFirstBlock />
    <FooterSecondBlock
      v-if="footerSecondBlock"
      :sections="footerSecondBlock as FooterSecondBlockSection[]"
      @on-link-click="handleLinkClick"
    />
    <FooterThirdBlock @on-social-click="dispatchSocialEvent" />
    <FooterCopyright />
    <!-- SIDEBARS -->
    <FooterAsideLanguage v-bind="{ countries }" />
  </footer>
</template>
