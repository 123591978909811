<script lang="ts" setup>
const { storeLocator } = useRouteHelper()
const { dispatchStoreLocatorEvent } = useGANavigationEvents()

const dispatchEvent = () => {
  dispatchStoreLocatorEvent()
}
const { isShoppable } = await useIsShoppable()
</script>

<template>
  <div
    data-testid="footer-first-block"
    class="bg-neutral-white text-primitives-black lg:grid-standard !gap-md positive-padding flex w-full flex-col border-t py-12 lg:py-16"
  >
    <div class="col-span-4" v-if="isShoppable">
      <FooterNewsLetter />
    </div>
    <div class="col-span-3 col-start-7">
      <div class="gap-lg col-span-4 flex flex-col">
        <h3 class="text-medium-6 lg:pb-2">
          {{ $ts('footer.firstBlock.storeLocator') }}
        </h3>

        <MoleculesLinkWrapper
          class="!text-light-6 w-fit"
          :to="`${storeLocator}search`"
          anatomy="link"
          underline-effect="appear"
          @click="dispatchEvent()"
        >
          <template #label>
            {{ $ts('footer.firstBlock.ourLocations') }}
          </template>
        </MoleculesLinkWrapper>
      </div>
    </div>
    <div class="col-span-3">
      <div class="gap-lg flex flex-col">
        <h3 class="text-medium-6 lg:pb-2">
          {{ $ts('footer.firstBlock.countryRegion') }}
        </h3>
        <FooterFirstBlockProgressiveLabel />
      </div>
    </div>
  </div>
</template>
