<script setup lang="ts">
import type { HeaderNavigationBarProps } from './HeaderNavigationBar.props'

defineProps<HeaderNavigationBarProps>()

const navigationBar = ref<HTMLElement>()
const { height } = useElementSize(
  navigationBar,
  { width: 0, height: 0 },
  { box: 'border-box' }
)

watch(
  () => height.value,
  height => {
    if (document) {
      document.documentElement.style.setProperty(
        '--navbar-height',
        `${height}px`
      )
    }
  }
)

const { isCountryShoppable } = await useIsShoppable()
</script>

<template>
  <nav
    id="navigation"
    ref="navigationBar"
    aria-label="navigation-menu"
    role="navigation"
    class="bg-primitives-white text-primitives-black border-primitives-grey-100 fixed bottom-0 left-0 right-0 z-[51] h-[68px] border-t-[0.5px] px-4 py-2.5 lg:hidden print:hidden"
  >
    <HeaderActionContainer
      class="justify-around gap-2 [&>li]:flex-1 [&>li]:justify-center"
    >
      <template
        #default="{
          isUserLogged,
          isShopActive,
          isListViewActive,
          menuStatus,
          addToCardStatus,
          openSearch,
          toggleMenu,
          toggleMenuInPdp,
          toggleMyAccount,
          toggleListView,
        }"
      >
        <template v-if="anatomy === 'pdp'">
          <HeaderActionMenu
            :is-active="menuStatus.isActive"
            @click-handler="toggleMenuInPdp"
          />
          <HeaderActionSearch
            v-if="!isFederatedApp"
            :show-label="false"
            @click-handler="openSearch"
          />
          <HeaderActionAccount
            v-if="menuStatus.isActive"
            :is-user-logged="isUserLogged"
            @click-handler="toggleMyAccount"
          />
          <HeaderActionCart v-if="isShopActive" />

          <!-- TODO: (new heder) check the entire component -->
          <HeaderActionAddToCart
            v-if="!menuStatus.isActive && isShopActive"
            class="ml-2 max-w-[50%] !basis-full"
            v-bind="addToCardStatus"
          />
        </template>
        <template v-else>
          <HeaderActionMenu
            :is-active="menuStatus.isActive"
            @click-handler="toggleMenu"
          />
          <HeaderActionSearch
            v-if="!isFederatedApp"
            :show-label="false"
            @click-handler="openSearch"
          />
          <HeaderActionListView
            v-if="anatomy === 'hp' && !isFederatedApp"
            :is-open="isListViewActive"
            @click-handler="toggleListView"
          />
          <HeaderActionAccount
            :is-user-logged="isUserLogged"
            @click-handler="toggleMyAccount"
          />
          <HeaderActionCart
            v-if="(isShopActive || isFederatedApp) && isCountryShoppable"
          />
        </template>
      </template>
    </HeaderActionContainer>
  </nav>
</template>
