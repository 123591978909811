<script setup lang="ts">
import type { HeaderHighlightProps } from './HeaderHighlight.props'
const { cleanMenuStatus } = useMenu()

withDefaults(defineProps<HeaderHighlightProps>(), {
  hideMobileImg: false,
})
</script>

<template>
  <NuxtLink
    v-if="to"
    :to="to"
    :external="external"
    :target="external ? '_blank' : ''"
    class="gap-sm flex flex-col"
    @click="cleanMenuStatus"
  >
    <NuxtPicture
      provider="cloudinary"
      :src="media.secure_url"
      :alt="caption"
      :class="[
        aspectRatio ? `aspect-${aspectRatio}` : '',
        hideMobileImg ? 'hidden lg:block' : '',
      ]"
      :img-attrs="{
        class: `h-full w-full object-cover ${aspectRatio ? ` aspect-${aspectRatio}` : ''}`,
      }"
      sizes="lg:25vw sm:90vw"
    />
    <span class="text-text-primary uppercase"> {{ caption }} </span>
  </NuxtLink>
  <template v-else>
    <NuxtPicture
      provider="cloudinary"
      :src="media.secure_url"
      :alt="caption"
      :class="[
        aspectRatio ? `aspect-${aspectRatio}` : '',
        hideMobileImg ? 'hidden lg:block' : '',
      ]"
      :img-attrs="{
        class: `h-full w-full object-cover ${aspectRatio ? ` aspect-${aspectRatio}` : ''}`,
      }"
      sizes="lg:25vw sm:90vw"
    />
    <span class="text-text-primary uppercase"> {{ caption }} </span>
  </template>
</template>
