<script lang="ts" setup>
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { HeaderCardType } from './HeaderListView.props'

const data = await useImgLogoBottomBar('layer-cross-brand')
const { isDialogOpen: isCartDialogOpen } = useDialog(SIDEBAR_IDS.headerSideCart)

const hasAlreadyBeenOpened = ref(false)

watchOnce(isCartDialogOpen, v => {
  hasAlreadyBeenOpened.value = true
})
</script>
<template>
  <HeaderListView
    :id="SIDEBAR_IDS.popupLayerListView"
    :cards="data as HeaderCardType[]"
  />
  <LoginSidebar />
  <!-- We set it to lazy so that it doesn't get bundled -->
  <!-- After it has been opened once and the relevant JS has been loaded -->
  <!-- we keep it loaded -->
  <LazyHeaderSideCart v-if="isCartDialogOpen || hasAlreadyBeenOpened" />
</template>
